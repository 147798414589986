import qs from 'qs';

const { REACT_APP_API_ENDPOINT, REACT_APP_S3_ENDPOINT } = process.env;

export const generateUrl = (
  url: string,
  params?: Record<string, unknown>,
): string => {
  const paramsString = qs.stringify(params, { arrayFormat: 'brackets' });

  const URL =
    paramsString !== ''
      ? `${REACT_APP_API_ENDPOINT}/${url}?${paramsString}`
      : `${REACT_APP_API_ENDPOINT}/${url}`;

  return URL;
};

export const wrapFetch = async <T = Record<string, unknown>>(
  url: string,
  options: RequestInit = { headers: {} },
  params = {},
): Promise<T> => {
  const URL = generateUrl(url, params);

  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  });

  const result = await fetch(URL, { ...options, headers });

  return result.json();
};

export const fetchS3Data = async <T = Record<string, unknown>>(
  url: string,
  options: RequestInit = { headers: {} },
  params = {},
): Promise<T> => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...options.headers,
  });

  const result = await fetch(`${REACT_APP_S3_ENDPOINT}/${url}`, {
    ...options,
    headers,
  });

  return result.json();
};
