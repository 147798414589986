import React from 'react';

import { ReactComponent as Logo } from 'images/logo.svg';

import styles from './index.module.css';
import Button from 'components/atoms/Button';
import { useWallet } from 'models/wallet';

const Header: React.FC = () => {
  const [{ myAddress }, { connecWallet }] = useWallet();

  return (
    <div className={styles.header}>
      <div className={styles.left}>
        <Logo></Logo>
        <div className={styles.navigation}>
          <a href="/">Generate url</a>
          <a href="/">Transfer</a>
        </div>
      </div>
      <div className={styles.right}>
        <div className={styles.action}>
          {myAddress !== '' ? (
            <Button outline>{`${myAddress.substr(0, 4)}...${myAddress.substr(
              -4,
            )}`}</Button>
          ) : (
            <Button outline onClick={connecWallet}>
              Connect Wallet
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
