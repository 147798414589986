import React from 'react';
import classnames from 'classnames';

import styles from './index.module.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  outline?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  className,
  outline,
  children,
  ...other
}) => (
  <button
    className={classnames(styles.button, className, {
      [styles.outline]: outline,
    })}
    type="button"
    {...other}
  >
    {children}
  </button>
);

export default Button;
