import { createAction, handleActions, Action } from 'redux-actions';
import { Transaction, SystemProgram, PublicKey } from '@solana/web3.js';

import { useRedux, WrapActionDispatch } from 'util/hook/redux';
import { CONNECTION } from 'util/wallet';

import { State as GlobalState } from './reducers';
import { ThunkAction } from 'redux-thunk-fsa';

export enum TRANSFER_STATUS {
  'INIT' = 'INIT',
  'PENDING' = 'PENDING',
  'FULFILLED' = 'FULFILLED',
  'FAILED' = 'FAILED',
}

export const setTransferStatus = createAction<TRANSFER_STATUS, TRANSFER_STATUS>(
  'SET_TRANSFER_STATUS',
  status => status,
);

export const setTransferTxHash = createAction<string, string>(
  'SET_TRANSFER_TRANSACTION_HASH',
  txhash => txhash,
);

const createTransaction = async (
  from: string,
  to: string,
  number: number,
): Promise<string> => {
  console.log('start transfer', from, to, number);

  const FROM = new PublicKey(from);
  const TO = new PublicKey(to);

  // 建立一個空的tx物件
  let tx = new Transaction();

  // 加上tranfer的instruction
  tx.add(
    SystemProgram.transfer({
      fromPubkey: FROM,
      toPubkey: TO,
      lamports: 1e9 * number, // 1 SOL
    }),
  );

  // 指定fee payer
  tx.feePayer = FROM;

  const recentBlockhash = await CONNECTION.getRecentBlockhash('finalized');

  console.log(recentBlockhash);

  tx.recentBlockhash = recentBlockhash.blockhash;

  // 給錢包簽名
  const signedTransaction = await window.solana.signTransaction(tx);
  const txhash = await CONNECTION.sendRawTransaction(
    signedTransaction.serialize(),
  );
  console.log(`txhash: ${txhash}`);

  return txhash;
};

export const processTransfer = createAction<
  ThunkAction<Promise<void>, GlobalState, any, any>,
  string,
  number
>('PROCESS_TRANSFER', (recipient, amount) => async (dispatch, getState) => {
  const {
    wallet: { myAddress },
  } = getState() as GlobalState;

  console.log(myAddress, recipient, amount);

  if (myAddress === '') {
    return;
  }

  const txhash = await createTransaction(myAddress, recipient, amount);

  await dispatch(setTransferTxHash(txhash));
  await dispatch(setTransferStatus(TRANSFER_STATUS.PENDING));

  const result = await CONNECTION.confirmTransaction(txhash);

  console.log(result.value.err);

  if (result.value.err) {
    await dispatch(setTransferStatus(TRANSFER_STATUS.FAILED));
  } else {
    await dispatch(setTransferStatus(TRANSFER_STATUS.FULFILLED));
  }
});

// For Global State usage
export interface State {
  status: TRANSFER_STATUS;
  transactionHash: string;
}

export const defaultState: State = {
  status: TRANSFER_STATUS.INIT,
  transactionHash: '',
};

export const reducer = {
  transfer: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
    {
      SET_TRANSFER_TRANSACTION_HASH: (state, action: Action<string>) => ({
        ...state,
        transactionHash: action.payload,
      }),

      SET_TRANSFER_STATUS: (state, action: Action<TRANSFER_STATUS>) => ({
        ...state,
        status: action.payload,
      }),
    },
    defaultState,
  ),
};

const mapHooksToState = (
  state: GlobalState,
): { status: TRANSFER_STATUS; transactionHash: string } => ({
  status: state.transfer.status,
  transactionHash: state.transfer.transactionHash,
});

const transferActionsMap = {
  processTransfer,
};

type TransferSelector = ReturnType<typeof mapHooksToState>;
type TransferActionsMap = typeof transferActionsMap;

export const useTransfer = (): [
  TransferSelector,
  WrapActionDispatch<TransferActionsMap>,
] =>
  useRedux<TransferSelector, TransferActionsMap>(
    mapHooksToState,
    transferActionsMap,
  );
