import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import Router from 'layouts/Router';

import configureStore from './store';
import history from './store/history';
import routes from './routes';
import { defaultState } from './models/reducers';

import 'normalize.css';

import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';

const store = configureStore(defaultState);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history} routes={routes} store={store} />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
