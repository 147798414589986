import { combineReducers } from 'redux';

import * as routing from './routing';
import * as address from './address';
import * as wallet from './wallet';
import * as transfer from './transfer';

// For Global State interface
export interface State {
  routing: routing.State;
  address: address.State;
  wallet: wallet.State;
  transfer: transfer.State;
}

export const defaultState: State = {
  routing: routing.defaultState,
  address: address.defaultState,
  wallet: wallet.defaultState,
  transfer: transfer.defaultState,
};

const reducers = combineReducers<State>({
  ...routing.reducer,
  ...address.reducer,
  ...wallet.reducer,
  ...transfer.reducer,
});

export default reducers;
