import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux, WrapActionDispatch } from 'util/hook/redux';
import { fetchS3Data } from 'util/api';

import { State as GlobalState } from './reducers';

interface RecipientAddressRes {
  address: string;
}

export const getRecipientAddress = createAction<Promise<string>, string>(
  'GET_RECIPIENT_ADDRESS',
  async hash => {
    try {
      const data = await fetchS3Data<RecipientAddressRes>(`s/${hash}.json`);
      return data.address;
    } catch (error) {
      return '';
    }
  },
);

// For Global State usage
export interface State {
  loading: boolean;
  recipient: string;
}

export const defaultState: State = {
  loading: false,
  recipient: '',
};

export const reducer = {
  address: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
    {
      GET_RECIPIENT_ADDRESS_PENDING: state => ({
        ...state,
        loading: true,
      }),

      GET_RECIPIENT_ADDRESS_FULFILLED: (state, action: Action<string>) => ({
        ...state,
        recipient: action.payload,
        loading: false,
      }),
    },
    defaultState,
  ),
};

const mapHooksToState = (state: GlobalState): { recipient: string } => ({
  recipient: state.address.recipient,
});

const addressActionsMap = {
  getRecipientAddress,
};

type AddressSelector = ReturnType<typeof mapHooksToState>;
type AddressActionsMap = typeof addressActionsMap;

export const useAddress = (): [
  AddressSelector,
  WrapActionDispatch<AddressActionsMap>,
] =>
  useRedux<AddressSelector, AddressActionsMap>(
    mapHooksToState,
    addressActionsMap,
  );
