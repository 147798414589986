import { useState, useEffect } from 'react';

import { History, Location } from 'history';

const useLocation = (history: History): Location => {
  const [location, setLocation] = useState(history.location);

  useEffect(() => {
    const unlisten = history.listen(setLocation);
    return () => unlisten();
  }, [history]);

  return location;
};

export default useLocation;
