import React from 'react';

import { getRecipientAddress } from 'models/address';

import { CustomRoute } from 'util/hook/useRouter';

const childrenHomeRoute: CustomRoute = {
  path: '/:hash',
  components: () => [import(/* webpackChunkName: 'home' */ './component')],
  render: ([Home]) => <Home />,
  onEnter: async ({ next, params, store }) => {
    console.log('on Enter s');

    await store.dispatch(getRecipientAddress(params.hash as string));

    const children = await next();
    console.log('on Enter s / end');
    return children;
  },
};

const routes: CustomRoute = {
  path: '/s',
  components: () => [import(/* webpackChunkName: 's' */ './component')],
  render: ([Transfer]) => <Transfer />,
  onEnter: async () => {
    console.log('on Enter s home');

    console.log('on Enter s home/ end');
  },
  children: [childrenHomeRoute],
};

export default routes;
