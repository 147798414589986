import { createAction, handleActions, Action } from 'redux-actions';

import { useRedux, WrapActionDispatch } from 'util/hook/redux';
import { getProvider } from 'util/wallet';

import { State as GlobalState } from './reducers';

export const connecWallet = createAction<Promise<string>>(
  'CONNECT_WALLET',
  async () => {
    const privider = getProvider();
    const resp = await privider.connect();
    const myaddress = resp.publicKey.toString();

    return myaddress;
  },
);

// For Global State usage
export interface State {
  loading: boolean;
  myAddress: string;
}

export const defaultState: State = {
  loading: false,
  myAddress: '',
};

export const reducer = {
  wallet: handleActions<State, any>( // eslint-disable-line @typescript-eslint/no-explicit-any
    {
      CONNECT_WALLET_PENDING: state => ({
        ...state,
        loading: true,
      }),

      CONNECT_WALLET_FULFILLED: (state, action: Action<string>) => ({
        ...state,
        myAddress: action.payload,
        loading: false,
      }),
    },
    defaultState,
  ),
};

const mapHooksToState = (state: GlobalState): { myAddress: string } => ({
  myAddress: state.wallet.myAddress,
});

const walletActionsMap = {
  connecWallet,
};

type WalletSelector = ReturnType<typeof mapHooksToState>;
type WalletActionsMap = typeof walletActionsMap;

export const useWallet = (): [
  WalletSelector,
  WrapActionDispatch<WalletActionsMap>,
] =>
  useRedux<WalletSelector, WalletActionsMap>(mapHooksToState, walletActionsMap);
