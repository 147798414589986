import { Connection } from '@solana/web3.js';
declare global {
  interface Window {
    solana: any;
  }
}

export const getProvider = () => {
  if ('solana' in window) {
    const provider = window.solana;

    console.log(provider);
    if (provider.isPhantom) {
      return provider;
    }
  }
  window.open('https://phantom.app/', '_blank');
};

const RPC_ENDPOINT = `${process.env.REACT_APP_API_ENDPOINT}/proxy-rpc`;

export const CONNECTION = new Connection(RPC_ENDPOINT, {
  wsEndpoint: process.env.REACT_APP_WS_ENDPOINT,
});
